import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert2';
import withRouter from './WithRouter';
import Modal from 'react-modal';
import { modalStyles } from '../utils/styles';
import { getVideoTutorial } from '../utils/videotutorials';

Modal.setAppElement(document.getElementById('root'));

const NavBar = (props) => {
    const [modal, setModal] = useState(false);
    const [videotutorial, setVideotutorial] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Buscar el videotutorial correspondiente a la pantalla
        const vt = getVideoTutorial(location.pathname);
        setVideotutorial(vt);
    }, [location.pathname]);

    const signout = () => {
        swal.fire({
            title: "Logout?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Sign Out',
            cancelButtonText: 'Stay',
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger ml-2",
                cancelButton: "btn btn-secondary active mr-2",
            }
        }).then(
            (value) => {
                if (value.isConfirmed) {
                    props.logout();
                    props.router.navigate('');
                }
            }
        );
    };

    useEffect(() => {
        $(".js__drop_down_button").on("click", function () {
            if ($(window).width() > 1025) {
                $(".js__drop_down").toggleClass("active");
            }
        });
    });

    return (
        <>
            <div className="fixed-navbar">
                <div className="float-left">
                    <button data-tour="6" type="button" className="menu-mobile-button fas fa-bars js__menu_mobile"></button>
                    <h1 className="page-title">{props.brand.name}</h1>
                </div>
                <div className="float-right">
                    {
                        props.brand?.addons && props.brand.addons.length > 0
                            ?
                            <div className="ico-item toggle-hover js__drop_down">
                                <span className="fas fa-th js__drop_down_button"></span>
                                <div className="toggle-content">
                                    <ul className="brand-assets">
                                        {
                                            props.brand.addons.map((item, index) => <li key={index}><a href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.icon}></i><span className="txt">{item.name}</span></a></li>)
                                        }
                                    </ul>
                                </div>
                            </div>
                            :
                            null
                    }
                    {videotutorial && <span data-tour="1" onClick={() => setModal(true)} className="ico-item notice-alarm"><i className="fas fa-question-circle" /></span>}
                    <NavLink data-tour="5" to="/profile" className="ico-item notice-alarm"><i className="fas fa-user-cog" /></NavLink>
                    {process.env.REACT_APP_SLACK_URL && <a data-tour="4" href={process.env.REACT_APP_SLACK_URL} className="ico-item notice-alarm" target="_blank" rel="noreferrer"><i className="fab fa-slack" /></a>}
                    <a data-tour="2" href="https://docs.google.com/document/d/1WJsq2wsvNT1t-qdfDGNvXsnlTrknet4JhD3QzzRxcXM/edit?usp=sharing" className="ico-item notice-alarm" target="_blank" rel="noreferrer"><i className="fas fa-book" /></a>
                    <i className="ico-item"></i>
                    <span className="ico-item fas fa-power-off" onClick={signout}></span>
                </div>
            </div>
            <Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                style={modalStyles}
                contentLabel="Videotutorial"
            >
                {
                    videotutorial &&
                    <div className="loom-container"><iframe title="loom" src={`https://www.loom.com/embed/${videotutorial.loom_id}`} frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen className="loom-iframe"></iframe></div>
                }
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    token: state.auth.token,
    brand: state.brand,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: 'LOGOUT' })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));