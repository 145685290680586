import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Error500 from './components/assets/Error500';
import { TourProvider } from '@reactour/tour';
import { steps } from './utils/tourSteps';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ErrorBoundary fallbackRender={Error500}>
      <TourProvider  showBadge={false} padding={0} steps={steps}>
        <App />
      </TourProvider>,
    </ErrorBoundary>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
