export const steps = [
  {
    selector: '[data-tour="1"]',
    position: 'bottom',
    padding: { mask: [10, 0]},
    content: 'Cada pantalla tiene su propio video tutorial que te guiará a través de todas las funciones disponibles.',
  },
  {
    selector: '[data-tour="2"]',
    position: 'bottom',
    padding: { mask: [10, 0]},
    content: `Si tienes dudas, puedes consultar el manual de usuario para obtener detalles completos sobre cada funcionalidad. ¡Es tu guía completa para sacar el máximo provecho de nuestra plataforma!`,
  },
  {
    selector: '[data-tour="3"]',
    position: 'bottom',
    padding: { mask: 4},
    content: `Nuestro centro de ayuda está repleto de respuestas a las consultas más comunes. Encuentra tutoriales y soluciones rápidas para aplicar tus campañas de fidelización.`,
  },
  {
    selector: '[data-tour="4"]',
    position: 'bottom',
    padding: { mask: [10, 0]},
    content: `¡La comunidad te espera en nuestro canal de Slack! Nuestro equipo de soporte está disponible para brindarte soporte personalizado.`,
  },
  {
    selector: '[data-tour="5"]',
    position: 'bottom',
    padding: { mask: [10, 0]},
    content: `Administra tu perfil, comprueba que tus datos sean correctos, elige el formato de fecha/hora y zona horaria.`,
  },
  {
    selector: '[data-tour="6"]',
    position: 'bottom',
    padding: { mask: 0},
    content: `Toca aquí para simplificar el menú. Libera espacio en pantalla y enfocate en lo que realmente importa.`,
  },
];