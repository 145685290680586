import React, {useEffect} from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';
import { init, cancel } from '../services/admin';

const AppContainer = props => {
    useEffect(() => {
        init();

        return () => {
            if (cancel) {
                return cancel();
            }
        };
    }, []);
    
    return (
        <main>
            <NavBar />
            <SideBar />
            <div id="wrapper">
                <div className="main-content">
                    {props.children}
                    <Footer />
                </div>
            </div>
        </main>
    );
};

export default AppContainer;