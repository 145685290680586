const getVideoTutorial = (path) => {
    let bestMatch = null;

    for (let i = 0; i < videotutorials.length; i += 1) {
        if (path === videotutorials[i].path) {
            return videotutorials[i];
        }

        if (videotutorials[i].regex && path.match(videotutorials[i].regex)) {
            bestMatch = videotutorials[i];
        }
    }

    return bestMatch;
};

const videotutorials = [
    {
        path: '/',
        regex: null,
        loom_id: '8e99d3bed24c43f5ae963fde37204376',
        url: 'https://www.loom.com/share/8e99d3bed24c43f5ae963fde37204376',
    },
    {
        path: '/promos/create',
        regex: /\/promos\/edit/gi,
        loom_id: 'fda2be3742544b2f859c598c0a7c98e2',
        url: 'https://www.loom.com/share/fda2be3742544b2f859c598c0a7c98e2',
    },
    {
        path: '/notifications/new',
        regex: null,
        loom_id: 'c56ae23dacd447c3be5d83d07f9d46b5',
        url: 'https://www.loom.com/share/c56ae23dacd447c3be5d83d07f9d46b5',
    },
    {
        path: '/contents/create',
        regex: /\/contents\/edit/gi,
        loom_id: 'c9bd38fd5a2c41d7bc145ec70bde5216',
        url: 'https://www.loom.com/share/c9bd38fd5a2c41d7bc145ec70bde5216',
    },
    {
        path: '/missions/create',
        regex: /\/missions\/edit/gi,
        loom_id: '3f75a8ca703d455ea1c1099e7eea41c8?sid=555b6f69-d03e-4db6-8d2d-6c73a95703b3',
        url: 'https://www.loom.com/share/3f75a8ca703d455ea1c1099e7eea41c8?sid=555b6f69-d03e-4db6-8d2d-6c73a95703b3',
    },
    {
        path: '/admin-users/create',
        regex: /\/admin-users\/edit/gi,
        loom_id: '2535a382fb034fbcbd1e30118c471c73?sid=85563991-8055-4027-a46e-7115c93c6b9f',
        url: 'https://www.loom.com/share/2535a382fb034fbcbd1e30118c471c73?sid=85563991-8055-4027-a46e-7115c93c6b9f',
    },
];

module.exports = { getVideoTutorial };
