export const modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      maxWidth              : '80%',
      transform             : 'translate(-50%, -50%)',
      maxHeight             : '80%',
    }
};

export const modalColumnStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    overflow              : 'visible',
    bottom                : 'auto',
    marginRight           : '-50%',
    maxWidth              : '80%',
    minWidth                 : '50vw',
    transform             : 'translate(-50%, -50%)',
    maxHeight             : '80%',
  }
};